import React from "react";
import styles from "./Extras.module.css";
import play from "../media/kids_play.svg";
import music from "../media/music.svg";

function Extras() {
  return (
    <div id="ostalo" className={styles.extrasContainer}>
      <div className={styles.leftContainer}>
        <div className={styles.titleAndIcon}>
          <img src={play} />
          <p>važnost igre</p>
        </div>
        <div className={styles.playDescription}>
          <p>
            Igra je sastavni dio djetetovog života, a želja za njome proizlazi
            iz unutrašnje potrebe za kretanjem. Igra predstavlja najprirodniju i
            vrlo značajnu aktivnost.
          </p>
          <ul>
            <li>Igra je osnovni način učenja o sebi, drugima i okolini.</li>
            <li>
              Igrajući se, dijete uči, razvija se, otkriva sve sfere svoga
              života.
            </li>
            <li>
              Igrom dijete razvija osjećaj samokontrole, sigurnosti, razvija
              samorefleksiju te vještine na antropološkim područjima (motoričke,
              emocionalne, kognitivne, socijalne) te jača samopouzdanje.
              <li>
                U igri se stvara bliskost, smijeh i zadovoljstvo. Smanjuje se
                kortizol, a povećava se sreća.
              </li>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.rightContainer}>
        <div className={styles.titleAndIcon}>
          <img src={music} />
          <p>važnost glazbe</p>
        </div>
        <div className={styles.playDescription}>
          <p>Glazba ima čarobnu moć u vježbanju.</p>
          <ul>
            <li>
              Ona pozitivno utječe na raspoloženje i na fizičke i umne
              sposobnosti te je veoma važna za razvoj svakog djeteta.
            </li>
            <li>
              Slušajući glazbu, već od najranije dobi, djeca sama spontano
              potiču ples.
            </li>
            <li>
              S njome se povećava osjećaj sreće i pozitivno okruženje.
              <li>Unosi ljepotu i radost u svakodnevni život.</li>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Extras;
