import styles from './KidsFitness.module.css'
import kidsfitness from '../media/fitnes.png'
import React, {useState} from 'react';

function KidsFitness() {
    const [isVisible, setIsVisible] = useState(false)
    return (
    <div className={styles.sectionContainer}>
        <div className={styles.sectionLeft}>
            <div>
                <p className={styles.sectionTitle}>FITNES ZA DJECU</p>
            </div>
            <div>
                <p className={styles.sectionDescription}>Uz fitnes program, prilagođen mlađim uzrastima, vaše će dijete učiti i usavršavati strukturu gibanja koja objedinjuju mehaničke i energetske elemente i ritam kako bi se ostvario temeljni cilj programa: <b><i>aktivno dijete = zdravo dijete</i></b></p>
            </div>
            {isVisible && (
            <div className={styles.hiddenText}>
            <p>
            Primijenjeno područje kineziologije, rekreacija za djecu koristi znanstveno utemeljene metode u programiranju programa vježbanja koji utječu na fiziološki biotički način održavanja vitalnosti organizma, poboljšavajući njegove sposobnosti i funkcije.

            </p>
            <p>Strukturirani fitnes program za djecu omogućuje razvijanje i usavršavanje motoričkih, funkcionalnih i kognitivnih sposobnosti, kao i razvijanje socijalnih vještina.</p>
        </div>
            )}

            <div className={styles.buttonContainer}>
                <button onClick={() => setIsVisible(!isVisible)}>{isVisible? 'Prikaži manje' : 'Prikaži više'}</button>
            </div>

        </div>
        <div className={styles.sectionRight}>
            <img src={kidsfitness}/>
        </div>
    </div>);
}

export default KidsFitness;