import styles from "./Gallery.module.css";
import React, { useEffect } from "react";
import { Image } from "antd";
import galleryImg from "../media/kids_fitnes.jpg";
import galleryImg2 from "../media/kids_dancing.png";
import galleryImg3 from "../media/kids_exercise.jpeg";
import galleryImg4 from "../media/kids_dancing_2.jpeg";

function Gallery() {
  return (
    <div id="galerija" className={styles.galleryContainer}>
            <div className={styles.title}>
        <p>galerija</p>
      </div>
      <Image.PreviewGroup
        preview={{
          onChange: (current, prev) =>
            console.log(`current index: ${current}, prev index: ${prev}`),
        }}
      >
        <div className={styles.galleryWrap}>
        <div className={styles.galleryContent}>
          <div className={styles.image}>
          <Image  src={galleryImg} />
          </div>
          <div className={styles.image}>
          <Image  src={galleryImg2} />
          </div>
          <div className={styles.image}>
          <Image  src={galleryImg3} />
          </div>
          <div className={styles.image}>
          <Image  src={galleryImg4} />
          </div>
        </div>
        <div className={styles.galleryContent}>
        <div className={styles.image}>
          <Image  src={galleryImg} />
          </div>
          <div className={styles.image}>
          <Image  src={galleryImg2} />
          </div>
          <div className={styles.image}>
          <Image  src={galleryImg3} />
          </div>
          <div className={styles.image}>
          <Image  src={galleryImg4} />
          </div>
        </div>
        </div>
       
      </Image.PreviewGroup>
    </div>
  );
}

export default Gallery;
