import React from "react";

import logo from '../media/logo_katarina.png'
import landing from '../media/landing_text.png'
import styles from './Header.module.css'
import './Header.css'

import { slide as Menu } from 'react-burger-menu'

function Header() {
    const goToElement = (id) => {
        const elem = document.getElementById(id); 
        window.scrollTo({
          top:elem.offsetTop,
          behavior:"smooth"
      });
    };

    return ( 
    <div>
        <div className={styles.headerContainer}>
            <div className={styles.logoContainer}>
                <img src={logo} />
                <p>Sportska udruga za djecu i mlade</p>
            </div>
            <div className={styles.navigationContainer}>
                <button>Naslovna</button>
                <button onClick={() => goToElement("galerija")}>Galerija</button>
                <button onClick={() => goToElement("ostalo")}>Ostalo</button>
                <button onClick={() => goToElement("kontakt")}>Kontakt</button>
            </div>
        </div>
        <div className={styles.landingContainer}>
            <div className={styles.landingImgContainer}>
                <p className={styles.landingImgText}>Sportska udruga za djecu i mlade</p>
                <img src={landing}/>
            </div>
        </div>
    </div>);
}

export default Header;