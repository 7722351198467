import styles from "./Contact.module.css";
import React from "react";
import phone from "../media/phone_icon.svg";
import email from "../media/email_icon.svg";
import location from "../media/location_icon.svg";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";

function Contact() {
  return (
    <div id="kontakt" className={styles.contactContainer}>
      <div className={styles.title}>
        <p>kontakt</p>
      </div>
      <div className={styles.infoContainer}>
        <div className={styles.leftContainer}>
            <img src={phone} />
            {/* <PhoneOutlined style={{fontSize: '50px', color: '#2F5597'}}/> */}
            <p>099/204 66 66</p>

        </div>
        <div className={styles.middleContainer}>
          <img src={email} />
            {/* <MailOutlined style={{fontSize: '50px', color: '#2F5597'}}/> */}
            <p>gym22dance@gmail.com</p>

        </div>
        <div className={styles.rightContainer}>
          <img src={location} />
            {/* <EnvironmentOutlined style={{fontSize: '50px', color: '#2F5597'}}/> */}

              <p>Hrvački klub Sesvete, Bistrička ul. 9/1, 10360, Sesvete</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
