import React from "react";

import styles from "./Summary.module.css";
import logo from "../media/logo_katarina.png";

function Summary() {
  return (
    <div className={styles.summaryContainer}>
      <div className={styles.title}>
        <p>kratki sadržaj programa</p>
      </div>
      <div className={styles.listsContainer}>
        <div className={styles.leftContainer}>
        <img src={logo} />
        </div>
        <div className={styles.bulletsContainer}>
          <div className={styles.middleContainer}>
            <div className={styles.list}>
              <ul>
                <li>Kondicijski programi niskog i visokog intenziteta</li>
                <li>Aerobik za djecu</li>
                <li>Ritmika</li>
                <li>Vježbe snage bez/sa pomagalima</li>
                <li>Vježbe za stretching</li>
              </ul>
            </div>
          </div>
          <div className={styles.rightContainer}>
          <div className={styles.list}>
              <ul>
                <li>Škola hodanja</li>
                <li>Škola trčanja</li>
                <li>Igre za motoričko učenje</li>
                <li>Igre za zabavu i smijeh</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Summary;
