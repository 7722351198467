import React from "react";

import styles from './Intro.module.css'
import kids from '../media/kids_play.png'

function Intro() {
    return ( 
    <div className={styles.introContainer}>
        <div className={styles.messageContainer}>
            <img src={kids}/>
        </div>
        <div className={styles.textContainer}>
            <p className={styles.goalText}>AKTIVNO DIJETE = ZDRAVO DIJETE</p>
            <div className={styles.descriptionText}>
                <p>Sportska udruga „Gym ´n dance” nudi grupne treninge za djecu u cilju zadovoljavanja potrebe za održavanjem, poboljšanjem i unapređenjem psihofizičkih sposobnosti i zdravlja djeteta.</p>
                <p>Upisom u naše sportske programe, dijete će na kvalitetan način obogatiti svoje slobodno vrijeme tjelesnim vježbanjem i sportskim aktivnostima. Treninzi su dostupni za djecu bez obzira na njihov nivo sposobnosti.</p>
            </div>

        </div>
    </div>);
}

export default Intro;