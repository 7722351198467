import React, { Component, useState }  from 'react';

import logo from "./logo.svg";
import "./App.css";
import Header from "./layout/Header";
import Intro from "./layout/Intro";
import KidsFitness from "./layout/KidsFitness";
import DanceFitnes from "./layout/DanceFitnes";
import Summary from "./layout/Summary";
import Extras from "./layout/Extras";
import Gallery from "./layout/Gallery";
import Contact from './layout/Contact';

import { slide as Menu } from 'react-burger-menu'

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
  };

  const handleStateChange = (state) => {
    setIsMenuOpen(state.isOpen);
  };

  return (
    <div id="outer-container">
      <Menu isOpen={isMenuOpen} onStateChange={handleStateChange} className="mobile-nav-container" reveal right pageWrapId={ "page-wrap" } outerContainerId={ "outer-container" }>
          <a id="home" onClick={() => handleCloseMenu()} className="menu-item" href="#">Naslovna</a>
          <a id="gallery" onClick={() => handleCloseMenu()} className="menu-item" href="#galerija">Galerija</a>
          <a id="other" onClick={() => handleCloseMenu()} className="menu-item" href="#ostalo">Ostalo</a>
          <a id="contact" onClick={() => handleCloseMenu()} className="menu-item" href="#kontakt">Kontakt</a>
      </Menu>
      <div id="page-wrap" className="App">
        <Header />
        <Intro />
        <KidsFitness />
        <DanceFitnes />
        <Summary />
        <Extras />
        <Gallery />
        <Contact />
      </div>
    </div>
  );
}

export default App;
