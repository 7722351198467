import styles from './DanceFitnes.module.css'
import React, {useState} from 'react';
import kidsDance from '../media/kids_dance.png'

function DanceFitnes() {
    const [isVisible, setIsVisible] = useState(false)

    return ( 
    <div className={styles.sectionContainer}>
        <div className={styles.leftContainer}>
            <img src={kidsDance} />
        </div>
        <div className={styles.rightContainer}>
            <div className={styles.sectionTitle}>
                <p>plesni fitnes za djecu</p>
            </div>
            <div className={styles.sectionDescription}>
                <p>Ples je pokret tijela kojeg svako dijete voli. Osim što ga voli, pozitivno utječe na njegov cjelokupni razvoj. Time se ostvaruje temeljni cilj programa: <b><i>aktivno dijete = zdravo dijete</i></b></p>
            </div>
            {isVisible && (
            <div className={styles.hiddenText}>
            <p>
            Dijete kroz ples izražava emocije i stječe iskustvo o vlastitom tijelu.
Plesnim aktivnostima kod djece razvijamo stvaralaštvo, osjećaj za ritam, harmoničnost, ljepotu pokreta i pravilno držanje tijela.
Utječemo na motoričke sposobnosti, poput: koordinacije, ravnoteže, fleksibilnost, preciznost… 
Aerobik je svaka ritmička, kontinuirana aktivnost koja se ponavlja i izvodi u određenom  vremenskom periodu s primjernim intenzitetom rada.
Elementi koji se koriste u aerobiku su: hodanje, trčanje, poskoci, skokovi, kao i plesni koraci koji međusobno povezujući daju ritmičko-lokomotornu cjelinu.
Temeljni cilj aerobika je razvijanje motoričkih sposobnosti poput dinamičke i statičke snage, gipkosti tijela, koordinacije te razvijanje aerobnih sposobnosti. 
Djeca kroz vježbe aerobika razgibavaju i jačaju cijelo tijelo.

            </p>
            {/* <p>Strukturirani fitnes program za djecu omogućuje razvijanje i usavršavanje motoričkih, funkcionalnih i kognitivnih sposobnosti, kao i razvijanje socijalnih vještina.</p> */}
        </div>
            )}

            <div className={styles.buttonContainer}>
                <button onClick={() => setIsVisible(!isVisible)}>{isVisible? 'Prikaži manje' : 'Prikaži više'}</button>
            </div>
        </div>
    </div> );
}

export default DanceFitnes;